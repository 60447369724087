import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 页面跳转的路由
const routes = [
  {
    path: '/',
    name: 'main',
    component: ()=> import('../views/MainView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('../views/CallBackView.vue')
  }
  ,
  {
    path: '/callbackqq',
    name: 'callbackqq',
    component: () => import('../views/CallBackQQView.vue')
  },
  {
    path: '/boundqq',
    name: 'boundqq',
    component: () => import('../views/BoundQQ.vue')
  },
  {
    path: '/boundgitee',
    name: 'boundgitee',
    component: () => import('../views/BoundGitee.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
