import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

  // 全局变量
  state: {
    isLoggedIn: false,
    userName: "",
    userId: "",
    qq:"暂未绑定",
    weChat: "暂未绑定",
    Gitee: "暂未绑定",
    isChange: false,
    avatar_url: require('@/assets/img/background.jpg'),
    bound: false,
    boundgitee: false
  },
  getters: { 
    avatar_url: state => state.avatar_url,
    userId: state => state.userId,
    bound: state => state.bound,
    boundgitee: state => state.boundgitee
  },
  // 用于设置全局变量的函数，可被调用
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setUsername(state, userName) {
      state.userName = userName;
    },
    setUserId(state,userId){
      state.userId = userId
    },
    setUserQQ(state,qq){
      state.qq = qq
    },
    setUserWeChat(state,wx){
      state.weChat = wx
    },
    setUserGitee(state,Gitee){
      state.Gitee = Gitee
    },
    setIsChange(state,isChange){
      state.isChange = isChange
    },
    setAvatarUrl(state,avatar_url){
      state.avatar_url = avatar_url
    },
    setBound(state,bound){
      state.bound = bound
    },
    setBoundGitee(state,boundgitee){
      state.boundgitee = boundgitee
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userName = "";
      state.userId = "";
      state.qq = "暂未绑定";
      state.weChat = "暂未绑定";
      state.Gitee = "暂未绑定";
      state.avatar_url = require('@/assets/img/background.jpg'); 
      state.bound = false;
      state.boundgitee = false;
      localStorage.clear();
    }
  },
  actions: {
    logout({ commit }) {
      commit('logout');
    },
    checkLogin({ commit }) {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      const userName = localStorage.getItem('userName');
      const userId = parseInt(localStorage.getItem('userId'));
      const qq = localStorage.getItem('qq');
      const weChat = localStorage.getItem('weChat');
      const Gitee = localStorage.getItem('Gitee');
      const bound = localStorage.getItem('bound');
      const boundgitee = localStorage.getItem('boundgitee');
      const avatar_url = localStorage.getItem('avatar_url');
      if (isLoggedIn && userName && userId) {
        commit('setIsLoggedIn', isLoggedIn);
        commit('setUsername', userName);
        commit('setUserId',userId);
        commit('setUserQQ', qq);
        commit('weChat',weChat);
        commit('setUserGitee', Gitee);
        commit('setBound', bound); 
        commit('setAvatarUrl',avatar_url)
        commit('setBoundGitee',boundgitee)
      }
    }
  },
  modules: {
  }
})
